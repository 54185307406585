<template>
  <v-container>
    <h1 class="my-4 heading-3">
      Gestion du contenu
    </h1>
    <v-card
      v-for="item in content"
      :key="item.id"
      :ref="'item-'+item.id"
      class="mb-3"
      color="blue lighten-3"
    >
      <v-toolbar
        dark
        color="blue lighten-1"
      >
        <v-toolbar-title class="text-uppercase">
          {{ item.type }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="selectedItem.id !== item.id"
          icon
          @click="onEdit(item)"
        >
          <v-icon>
            {{ icons.mdiPencil }}
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="onReset"
        >
          <v-icon>
            {{ icons.mdiCloseCircle }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container v-if="selectedItem.id === item.id">
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="selectedItem.title"
              label="Titre"
            />
            <v-textarea
              v-model="selectedItem.description"
              label="Description"
              auto-grow
            />
            <v-text-field
              v-model="selectedItem.subTitle"
              label="Sous-titre"
            />
            <h3 class="my-4">
              Gestion du visuel
            </h3>
            <v-text-field
              v-model="selectedItem.pictureTitle"
              label="Titre dans l'image"
            />
            <v-img
              :src="selectedItem.photo"
            />
            <v-file-input
              v-model="imageFile"
              show-size
              placeholder="Choisir une image"
              accept="image/png, image/jpeg"
              @change="onChange"
            />
            <h3 class="my-4">
              Gestion de la recherche
            </h3>
            <v-text-field
              v-model="selectedItem.searchTitle"
              label="Titre de la zone de recherche"
            />
            <p>Modification de la couleur</p>
            <v-color-picker
              v-model="selectedItem.color"
              dot-size="25"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              @click="onReset"
            >
              Annuler
            </v-btn>
            <v-btn
              small
              @click="onUpdateContent"
            >
              Mettre à jour
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiCloseCircle, mdiDelete, mdiPencil, mdiTune } from '@mdi/js'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  name: 'AdminContent',
  data () {
    return {
      imageFile: [],
      selectedItem: {
        id: -1,
        photo: null
      },
      icons: {
        mdiPencil,
        mdiDelete,
        mdiCloseCircle,
        mdiTune
      }
    }
  },
  computed: {
    ...mapState(['content'])
  },
  mounted () {
    this.bindContent()
  },
  methods: {
    ...mapActions(['bindContent', 'updateContent']),
    onEdit (item) {
      Object.assign(this.selectedItem, item)
    },
    async onUpdateContent () {
      await this.updateContent({ id: this.selectedItem.id, data: this.selectedItem })
      await this.onReset()
    },
    onChange (e) {
      const storageRef = firebase.storage().ref(`${this.selectedItem.type}-content`).put(e)
      return storageRef.then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          this.selectedItem.photo = url
        })
      })
    },
    onReset () {
      this.selectedItem = {
        id: -1
      }
      this.imageFile = []
    }
  }
}
</script>
